import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { APP_SETTINGS_TO_FETCH } from '../frameworkAppSettings/constants/AppSettings';
export function quickFetchFrameworkDataSchema({
  objectTypeId
}) {
  getFrameworkDataQuickFetchClient().then(client => {
    client.properties.get({
      frameworkTypeIdentifier: objectTypeId,
      query: {
        showHighlySensitiveProperties: true
      }
    });
    client.frameworkTypes.getObjects();
    client.typeMetadata.get({
      appSettingNames: APP_SETTINGS_TO_FETCH
    });
  }).catch(() => {});
}