import quickFetch from 'quick-fetch';
let __db = null;
export const __FOR_TESTING__ = () => __db;
export const NAMESPACE_PREFIX = 'hs.superstore';
export const OBJECT_STORE_NAME = 'default';
export const QF_SEARCH_METRIC_NAMESPACE = 'crm-object-search-query-utilities';
export const getStoreName = () => {
  return `${NAMESPACE_PREFIX}.${QF_SEARCH_METRIC_NAMESPACE}.${quickFetch.getPortalId()}`;
};
const open = () => {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open(getStoreName(), 3);
      if (openRequest) {
        openRequest.onerror = reject;

        // @ts-expect-error This is typed as a generic event, but it will have a `result` field
        openRequest.onupgradeneeded = ({
          target: {
            result
          }
        }) => {
          if (!result.objectStoreNames.contains(OBJECT_STORE_NAME)) {
            result.createObjectStore(OBJECT_STORE_NAME);
          }
        };

        // @ts-expect-error This is typed as a generic event, but it will have a `result` field
        openRequest.onsuccess = ({
          target: {
            result
          }
        }) => {
          // Set a global error handler on the db
          result.onerror = console.error;
          resolve(result);
        };
      }
    } catch (e) {
      reject(e);
    }
  });
};
const openIndexedDBConnection = () => {
  if (!__db) {
    __db = open();
  }
  return __db;
};

/**
 *
 * @param key The key to query in indexeddb
 * @returns A promise that resolves to a value, or rejects if the value is null/undefined or some other error ocurred
 */
export const getFromIDB = key => openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(OBJECT_STORE_NAME, 'readonly').objectStore(OBJECT_STORE_NAME).get(key);

    // @ts-expect-error This is typed as a generic event, but it will have a `result` field
    request.onsuccess = ({
      target: {
        result
      }
    }) => resolve(result);
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const getKeysFromIDB = () => openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(OBJECT_STORE_NAME, 'readonly').objectStore(OBJECT_STORE_NAME).getAllKeys();

    // @ts-expect-error This is typed as a generic event, but it will have a `result` field
    request.onsuccess = ({
      target: {
        result
      }
    }) =>
    // NOTE: Load-bearing != (not !==)
    resolve(result);
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const setInIDB = (key, value) => openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  try {
    const request = db.transaction(OBJECT_STORE_NAME, 'readwrite').objectStore(OBJECT_STORE_NAME).put(value, key);
    request.onsuccess = resolve;
    request.onerror = reject;
  } catch (e) {
    reject(e);
  }
}));
export const clearIDB = () => openIndexedDBConnection().then(db => new Promise((resolve, reject) => {
  const request = db.transaction(OBJECT_STORE_NAME, 'readwrite').objectStore(OBJECT_STORE_NAME).clear();
  request.onsuccess = resolve;
  request.onerror = reject;
}));
export const closeIDB = () => {
  if (!__db) {
    return Promise.resolve();
  }
  return openIndexedDBConnection().then(db => {
    db.close();
    __db = null;
  });
};