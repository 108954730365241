/*
 * WARNING: Do not add more imports into this file! It is used in a quick-fetch, and imports
 * will bloat the quick-fetch bundle, slowing down the app.
 */
import { APP_SETTINGS_TO_FETCH } from '../frameworkAppSettings/constants/AppSettings';
import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { quickFetchRecordCardProperties, quickFetchBoardFrameworkData } from 'crm-object-board/quickFetch';
import { crmObjectTableQuickFetch } from 'crm-object-table/quickFetch';
import { hasScope } from './hasScope';
export const doQuickFetches = ({
  objectTypeId,
  viewId,
  pipelineId,
  makeRestRequest,
  makeGqlRequest,
  afterAuth,
  setQuickFetchErrorOnWindow,
  pageType,
  redirect
}) => {
  const encodedTypeId = objectTypeId && encodeURIComponent(objectTypeId);
  const encodedViewId = viewId && encodeURIComponent(viewId);
  makeGqlRequest();
  if (objectTypeId) {
    if (pageType === 'board') {
      quickFetchRecordCardProperties(objectTypeId, pipelineId);
      quickFetchBoardFrameworkData(objectTypeId).catch(err => {
        setQuickFetchErrorOnWindow(err);
      });
    } else if (pageType === 'list') {
      crmObjectTableQuickFetch({
        objectTypeId,
        searchQueryVariablesLocalCacheKey: `${objectTypeId}:${viewId}`
      });
    }
    getFrameworkDataQuickFetchClient().then(client => {
      client.properties.get({
        frameworkTypeIdentifier: objectTypeId,
        query: {
          showHighlySensitiveProperties: true
        }
      });
      client.typeMetadata.get({
        appSettingNames: [...APP_SETTINGS_TO_FETCH, 'crmRecordsPage',
        //CRM LINKS
        'crmPageEditor' //CRM LINKS
        ]
      });
      client.pipelines.get({
        frameworkTypeIdentifier: objectTypeId
      });
      client.frameworkTypes.getObjects();
    }).catch(err => {
      setQuickFetchErrorOnWindow(err);
    });
    if (encodedViewId) {
      void makeRestRequest(`defaultView-${encodedViewId}`, `/sales/v4/views/default-view/${encodedTypeId}?viewId=${encodedViewId}&namespace=NONE`);
    } else {
      void makeRestRequest('defaultView', `/sales/v4/views/default-view/${encodedTypeId}?namespace=NONE`);
    }
  }
  if (afterAuth) {
    afterAuth(authData => {
      makeRestRequest('empty-state-redirect', `/sales-views/v2/crm-search/empty-state-redirect/${encodedTypeId}`).then(response => {
        if (response !== null && response !== void 0 && response.redirectLink) {
          redirect(response.redirectLink);
        }
      }).catch(() => {});
      if (encodedTypeId === '0-48' && hasScope(authData, 'crm-transcription')) {
        /* This quickfetch has been added ad-hoc by ml-fe-ci team: https://private.hubteam.com/janus/groups/hs-ml-fe-ci
        It pre-loads the state of the transcription setting to show onboarding features on the call index page
        Pre-fetching this setting allows to render the quick filter on load of the page without flickers or disable state changes
        For any questions/concerns, please use Slack channel: #sales-leader-insights-fe */

        void makeRestRequest('transcript-setting', '/transcript/v1/setting/transcript');
      }
    });
  }
};