/**
 * WARNING: Do not add imports into this file! It is used in a quick-fetch, and imports
 * will bloat the quick-fetch bundle, slowing down the app.
 *
 * Each of these cases correspond to the routes defined in the router.
 * @see https://git.hubteam.com/HubSpot/CRM/blob/61be3635fb8413a639c42ca38ce6ae999bf0cdf6/crm-index-ui/static/js/router/Router.js
 */
export const parseObjectTypeIdFromPath = path => {
  // legacy contacts routes
  if (path.match(/^\/contacts\/[0-9]+(\/|\/contacts.*)?$/i)) {
    return '0-1'; // Contact objectTypeId
  }

  // legacy companies routes
  if (path.match('/companies')) {
    return '0-2'; // Company objectTypeId
  }

  // legacy deals routes
  if (path.match('/deals')) {
    return '0-3'; // Deal objectTypeId
  }

  // legacy tickets routes
  if (path.match('/tickets')) {
    return '0-5'; // Ticket objectTypeId
  }

  // Generic object routes
  const match = path.match(/\/objects\/(\d+-\d+)/);
  if (!match) {
    return null;
  }
  return match[1];
};