export const getPipelineId = (objectTypeId, portalId) => {
  //try URL params first
  const urlParams = new URLSearchParams(window.location.search);
  const pipelineId = urlParams.get('pipelineId');
  if (pipelineId) {
    return pipelineId;
  }
  try {
    return JSON.parse(localStorage.getItem(`LocalSettings:Sales:CRM:defaultPipeline:${objectTypeId}:${portalId}`) || 'null');
  } catch (e) {
    return null;
  }
};