import { getFromIDB } from './persistence/indexeddb';
import { getGlobal, hasGlobal, setGlobal } from './persistence/global';
import { toNamespacedKey } from './utils/toNamespacedKey';
import stringify from 'fast-json-stable-stringify';
import quickFetch from 'quick-fetch';
const configKey = 'quickLoadConfig';
const defaultConfig = {
  quickLoadedRequests: new Map()
};
const getConfig = () => {
  if (!hasGlobal(configKey)) {
    setGlobal(configKey, defaultConfig);
  }
  return getGlobal(configKey);
};
export const resetConfig = () => {
  const config = getConfig();
  config.quickLoadedRequests = new Map();
};
export const hasQuickLoadRequest = ({
  namespace,
  entityName,
  cacheKey
}) => getConfig().quickLoadedRequests.has(toNamespacedKey(namespace, entityName, cacheKey));
export const getQuickLoadRequest = ({
  namespace,
  entityName,
  cacheKey
}) => getConfig().quickLoadedRequests.get(toNamespacedKey(namespace, entityName, cacheKey));
export const removeQuickLoadRequest = ({
  namespace,
  entityName,
  cacheKey
}) => getConfig().quickLoadedRequests.delete(toNamespacedKey(namespace, entityName, cacheKey));
const defaultQuickLoadPortalUserResolver = () => new Promise((resolve, reject) => {
  const timeout = setTimeout(() => reject(Error('Auth timed out')), 1000);
  quickFetch.afterAuth(auth => {
    var _auth$portal, _auth$user;
    clearTimeout(timeout);
    resolve({
      portalId: auth === null || auth === void 0 || (_auth$portal = auth.portal) === null || _auth$portal === void 0 ? void 0 : _auth$portal.portal_id,
      userId: auth === null || auth === void 0 || (_auth$user = auth.user) === null || _auth$user === void 0 ? void 0 : _auth$user.user_id
    });
  });
});
export const makeQuickLoadRequest = ({
  namespace,
  entityName,
  cacheKey,
  resolvePortalAndUserId = defaultQuickLoadPortalUserResolver
}) => {
  var _getQuickLoadRequest;
  if (!hasQuickLoadRequest({
    namespace,
    entityName,
    cacheKey
  })) {
    const key = toNamespacedKey(namespace, entityName, cacheKey);
    getConfig().quickLoadedRequests.set(key, getFromIDB({
      key,
      resolvePortalAndUserId
    }));
  }
  (_getQuickLoadRequest = getQuickLoadRequest({
    namespace,
    entityName,
    cacheKey
  })) === null || _getQuickLoadRequest === void 0 || _getQuickLoadRequest.catch(() => {
    // do nothing, this just makes our unhandled promise rejection tooling happy
    // in tests. If the failure is relevant, it will be consumed and handled in
    // withQuickLoad, otherwise we should ignore it.
  });
};
export const defaultToCacheKey = (...args) => stringify(args);
export const quickLoadPersistedPromise = (_ref, ...fetcherArgs) => {
  let {
    namespace,
    entityName,
    toCacheKey = defaultToCacheKey
  } = _ref;
  return makeQuickLoadRequest({
    namespace,
    entityName,
    cacheKey: toCacheKey(fetcherArgs)
  });
};