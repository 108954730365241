import { buildQuickFetchClientInstance } from './buildQuickFetchClientInstance';
export { makeGroupsWithPropertiesQuickFetchKey, getGroupsWithPropertiesFetchUrl, makeFrameworkTypesQuickFetchKey, makeFrameworkTypesFetchUrl, makePropertyMetadataEntriesFetchKey, makePropertyMetadataEntriesFetchUrl, makePipelinesFetchKey, makePipelinesFetchUrl } from './buildQuickFetchClientInstance';
export { makeTypeMetadataEntriesFetchKey, makeTypeMetadataEntryFetchKey, makeTypeMetadataEntriesFetchUrl, getTypeMetadataQuickFetches, takeTypeMetadataQuickFetch } from './quickFetchFunctions/typeMetadata';
let instance = null;
export const getFrameworkDataQuickFetchClient = () => {
  if (instance === null) {
    instance = buildQuickFetchClientInstance().catch(err => {
      throw err;
    });
  }
  return instance;
};