import { quickFetchFrameworkDataSchema } from './frameworkDataSchema';
import { quickFetchAssociationDefinitions } from './associationDefinitions';
import { quickFetchCrmObjectsSearch } from 'crm-object-search-query-utilities/quickFetch';

/**
 * Quick fetches crm-object-table framework data dependencies
 * as well as association definitions for the object type provided.
 *
 * @param {String} params.objectTypeId - The ID of the object type
 * to fetch data for.
 */
export function crmObjectTableQuickFetch({
  objectTypeId,
  searchQueryVariablesLocalCacheKey
}) {
  quickFetchFrameworkDataSchema({
    objectTypeId
  });
  quickFetchAssociationDefinitions({
    objectTypeId
  });
  if (searchQueryVariablesLocalCacheKey != null) {
    quickFetchCrmObjectsSearch({
      searchQueryVariablesLocalCacheKey
    }).catch(() => {});
  }
}