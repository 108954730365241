import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["operation"],
  _excluded2 = ["operation"];
import quickFetch from 'quick-fetch';
import { stableStringify } from './internal/stableStringify';

// eslint-disable-next-line hubspot-dev/no-declarations

// TODO delete after `importGql()` change is published
const makeGqlEarlyRequest_OLD = _ref => {
  let {
      operation
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  const requestName = stableStringify({
    operationName: operation.operationName,
    variables: operation.variables || {}
  });
  quickFetch.makeEarlyRequest(requestName, Object.assign({}, rest, {
    type: 'POST',
    data: JSON.stringify(operation),
    dataType: 'json',
    contentType: 'application/json'
  }));
};
const makeGqlEarlyRequest_NEW = _ref2 => {
  let {
      operation
    } = _ref2,
    rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  const requestName = stableStringify({
    id: operation.query.id,
    operationName: operation.query.operationName,
    variables: operation.variables || {}
  });
  const data = {
    operationName: operation.query.operationName,
    query: operation.query.query
  };
  if (operation.variables) {
    data.variables = operation.variables;
  }
  quickFetch.makeEarlyRequest(requestName, Object.assign({}, rest, {
    type: 'POST',
    data: JSON.stringify(data),
    dataType: 'json',
    contentType: 'application/json'
  }));
};
function makeGqlEarlyRequest(options) {
  if (typeof options.operation.query === 'object') {
    return makeGqlEarlyRequest_NEW(Object.assign({}, options, {
      operation: Object.assign({}, options.operation, {
        query: options.operation.query
      })
    }));
  } else {
    return makeGqlEarlyRequest_OLD(Object.assign({}, options, {
      operation: Object.assign({}, options.operation, {
        query: options.operation.query
      })
    }));
  }
}
// TODO delete after `importGql()` change is published
const getGqlEarlyRequest_OLD = ({
  operationName,
  variables
}) => {
  const requestName = stableStringify({
    operationName,
    variables: variables || {}
  });
  const requestState = quickFetch.getRequestStateByName(requestName);
  if (requestState && !requestState.error) {
    return new Promise((resolve, reject) => {
      requestState.whenFinished(response => {
        quickFetch.removeEarlyRequest(requestName);
        resolve(response);
      });
      requestState.onError((_, errorMessage) => {
        quickFetch.removeEarlyRequest(requestName);
        reject(errorMessage);
      });
    });
  }
  return null;
};
const getGqlEarlyRequest_NEW = ({
  operationName,
  variables,
  query
}) => {
  // If the graphqlParse plugin is not enabled the `query` will not have an `id` property
  if (typeof __GRAPHQL_PARSE_PLUGIN__ === 'undefined' || __GRAPHQL_PARSE_PLUGIN__ === false) {
    return null;
  }
  const requestName = stableStringify({
    operationName,
    variables: variables || {},
    id: query.id
  });
  const requestState = quickFetch.getRequestStateByName(requestName);
  if (requestState && !requestState.error) {
    return new Promise((resolve, reject) => {
      requestState.whenFinished(response => {
        quickFetch.removeEarlyRequest(requestName);
        resolve(response);
      });
      requestState.onError((_, errorMessage) => {
        quickFetch.removeEarlyRequest(requestName);
        reject(errorMessage);
      });
    });
  }
  return null;
};
function getGqlEarlyRequest(options) {
  return getGqlEarlyRequest_OLD(options) || getGqlEarlyRequest_NEW(options);
}
export { getGqlEarlyRequest, makeGqlEarlyRequest };