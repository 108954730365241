import quickFetch from 'quick-fetch';
import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
export function quickFetchAssociationDefinitions({
  objectTypeId
}) {
  makeGqlEarlyRequest({
    url: quickFetch.getApiUrl('/graphql/crm'),
    operation: {
      operationName: 'CrmObjectTableAssociationDefinitionsQuery',
      // @ts-expect-error importGql is untyped.
      query: importGql('CrmObjectTableAssociationDefinitionsQuery'),
      variables: {
        objectTypeId
      }
    }
  });
}