import quickFetch from 'quick-fetch';
import { makeGqlEarlyRequest } from 'apollo-link-hub-http/quickFetch';
import { getFromIDB } from './crmObjectSearch/indexdb';
export const QF_SEARCH_QF_CACHE_READ_HIT_METRIC = 'crm-object-search-query-utilities-qf-cache-hit';
export const QF_SEARCH_QF_CACHE_READ_MISS_METRIC = 'crm-object-search-query-utilities-qf-cache-miss';
export const QF_SEARCH_QF_CACHE_ERROR_METRIC = 'crm-object-search-query-utilities-qf-cache-read-error';
function logErrorAsWarning(error) {
  if (error instanceof Error) {
    performance.mark(QF_SEARCH_QF_CACHE_ERROR_METRIC);
    console.error(error.message);
  }
}
export async function quickFetchCrmObjectsSearch({
  searchQueryVariablesLocalCacheKey
}) {
  const variables = await getFromIDB(searchQueryVariablesLocalCacheKey).catch(error => {
    logErrorAsWarning(error);
    return null;
  });
  performance.mark(variables != null ? QF_SEARCH_QF_CACHE_READ_HIT_METRIC : QF_SEARCH_QF_CACHE_READ_MISS_METRIC);
  if (variables) {
    makeGqlEarlyRequest({
      url: quickFetch.getApiUrl('/graphql/crm'),
      operation: {
        operationName: 'CrmObjectsSearchQuery',
        // @ts-expect-error importGql is untyped.
        query: importGql('CrmObjectsSearchQuery'),
        variables
      }
    });
  }
}