/**
 * @note WARNING: Do not add imports into this file! It is used in a quick-fetch, and imports
 * will bloat the quick-fetch bundle, slowing down the app.
 */
export const getViewId = (pathname, objectTypeId) => {
  const [__matchString, pathViewId] = pathname.match(/.*\/views\/([^/]*)\/|$/) || [];
  const isRestore = Boolean(pathname.match('/restore'));
  if (isRestore) {
    try {
      const state = sessionStorage.getItem(`LocalSettings:Sales:${objectTypeId}:lastAccessedView:gridState`);
      if (!state) {
        return undefined;
      }

      // Because of course it's never easy, we double-stringify the value before putting
      // it in sessionStorage :pain:
      const parsedState = JSON.parse(JSON.parse(state));
      return parsedState.viewId;
    } catch (e) {
      // Do nothing, we'll just use the fallback view
    }
  }
  return pathViewId;
};