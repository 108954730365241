// TODO: This definitely won't work in a CF worker, but there's no data to quickLoad up there anyway.
// We'd probably need folks to duplicate their QF logic in a standard QF bundle in order to take advantage
// of quickLoading, but if the worker's fetching data it's likely faster than quickLoading anyway.
// Something to think about.

const globalStateKey = '__FDSR:GlobalSingletonState';
const fallback = {
  [globalStateKey]: {}
};
const getGlobalStorage = (storage = window) => {
  try {
    if (!storage[globalStateKey]) {
      storage[globalStateKey] = {};
    }
    return storage[globalStateKey];
  } catch (e) {
    // Fall back to storing in module
    return fallback[globalStateKey];
  }
};
export const __TEST_ONLY__clearGlobalStorage = (storage = window) => {
  try {
    storage[globalStateKey] = {};
  } catch (e) {
    // Do nothing
  }
};
export const getGlobal = key => getGlobalStorage()[key];
export const hasGlobal = key => {
  const global = getGlobalStorage();
  return global && global[key] !== undefined;
};
export const setGlobal = (key, value) => {
  const global = getGlobalStorage();
  if (global) {
    global[key] = value;
  }
};