import quickFetch from 'quick-fetch';
import { getGlobal, setGlobal } from '../persistence/global';
const typeMetadataQFStateKey = 'quickFetchedTypeMetadataRequests';
export const getTypeMetadataQuickFetches = () => getGlobal(typeMetadataQFStateKey) || new Map();
export const setTypeMetadataQuickFetches = value => setGlobal(typeMetadataQFStateKey, value);
const removeTypeMetadataQuickFetch = appSettingName => {
  const quickFetches = getTypeMetadataQuickFetches();
  quickFetches.delete(appSettingName);
  setTypeMetadataQuickFetches(quickFetches);
};
export const takeTypeMetadataQuickFetch = appSettingName => {
  const quickFetches = getTypeMetadataQuickFetches();
  const value = quickFetches.get(appSettingName);
  removeTypeMetadataQuickFetch(appSettingName);
  return value;
};

// The QF client doesn't pull in types, so we're just using any here.
// I definitely shouldn't have split them into two libraries, but here we are.
// This is duped from the main client.
const keyEntriesByAppSettings = entries => Object.entries(entries).reduce((mappedEntries, [fullyQualifiedName, appSettings]) => {
  Object.entries(appSettings).forEach(([appSettingName, metadata]) => {
    if (!mappedEntries[appSettingName]) {
      mappedEntries[appSettingName] = {};
    }
    if (!mappedEntries[appSettingName][fullyQualifiedName]) {
      mappedEntries[appSettingName][fullyQualifiedName] = metadata;
    }
  });
  return mappedEntries;
}, {});
export const makeTypeMetadataEntriesFetchKey = ({
  portalId = quickFetch.getPortalId(),
  appSettingNames
}) => `frameworkDataSchemaResolvers-${portalId}-typeMetadataEntries-${appSettingNames.slice().sort().join(',')}`;
export const makeTypeMetadataEntryFetchKey = ({
  portalId,
  appSettingName
}) => makeTypeMetadataEntriesFetchKey({
  portalId,
  appSettingNames: [appSettingName]
});
export const makeTypeMetadataEntriesFetchUrl = () => '/framework-builder/v1/read/metadata/type/all/batch';
export const quickFetchTypeMetadataEntries = ({
  appSettingNames
}) => {
  const quickFetchKey = makeTypeMetadataEntriesFetchKey({
    appSettingNames
  });
  const existingFetches = getTypeMetadataQuickFetches();

  // Filter down to only the app settings which haven't been quick-fetched yet
  const missingEntries = appSettingNames.filter(appSettingName => !existingFetches.has(appSettingName));
  if (missingEntries.length === 0) {
    return;
  }

  // Split the single top-level quick-fetch into a promise per app setting
  const basePromise = new Promise((resolve, reject) => {
    quickFetch.makeEarlyRequest(quickFetchKey, {
      url: quickFetch.getApiUrl(makeTypeMetadataEntriesFetchUrl(), true),
      dataType: 'json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify(missingEntries),
      whenFinished: results => {
        quickFetch.removeEarlyRequest(quickFetchKey);

        // Result is pre-keyed by appSetting for efficiency
        resolve(keyEntriesByAppSettings(results));
      },
      onError: err => {
        quickFetch.removeEarlyRequest(quickFetchKey);
        reject(err);
      }
    });
  });
  missingEntries.forEach(appSettingName => {
    const appSettingSpecificPromise = basePromise.catch(err => {
      // Remove this fetch from the list of quickFetches if it fails.
      removeTypeMetadataQuickFetch(appSettingName);

      // Rethrow the error just in case someone did manage to get a reference
      // to this promise before the failure was handled.
      throw err;
    });
    existingFetches.set(appSettingName, appSettingSpecificPromise);
    appSettingSpecificPromise.catch(() => {
      // Swallow the error here — we don't care if this rejects (it'll
      // be handled in client code) but it trips our unhandled promise rejection tooling.
    });
  });

  // Set promises on window for each app setting
  setGlobal(typeMetadataQFStateKey, existingFetches);
};