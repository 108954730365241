const globalStateKey = '__persist-promise:GlobalState';
const fallback = {
  [globalStateKey]: {}
};
const getGlobalStorage = (storage = window) => {
  try {
    if (!storage[globalStateKey]) {
      storage[globalStateKey] = {};
    }
    return storage[globalStateKey];
  } catch (e) {
    // Fall back to storing in module
    return fallback[globalStateKey];
  }
};
export const getGlobal = key => getGlobalStorage()[key];
export const hasGlobal = key => {
  const global = getGlobalStorage();
  return global && global[key] !== undefined;
};
export const setGlobal = (key, value) => {
  const global = getGlobalStorage();
  if (global) {
    global[key] = value;
  }
};
export const __TEST_ONLY__clearGlobal = () => setGlobal(globalStateKey, {});