import { quickFetchAppSettingsFeatures } from 'framework-app-settings-features-lib/quickFetch/quickFetchAppSettingsFeatures';
import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
export const getRecordCardKey = (objectTypId, pipelineId) => {
  return `CRM_OBJECT_BOARD_RECORD_CARD_${objectTypId}_${pipelineId || ''}`;
};
export const quickFetchBoardFrameworkData = objectTypeId => {
  return getFrameworkDataQuickFetchClient().then(client => {
    return Promise.all([client.properties.get({
      frameworkTypeIdentifier: objectTypeId,
      query: {
        showHighlySensitiveProperties: true
      }
    }), quickFetchAppSettingsFeatures(['crmTagsSettings', 'crm_board_settings', 'crm_object_pipelines']), client.pipelines.get({
      frameworkTypeIdentifier: objectTypeId
    }), client.frameworkTypes.getObjects()]);
  });
};
const BASE_URL = 'crm-record-cards/v1/pipeline-customization';
export const quickFetchRecordCardProperties = (objectTypeId, pipelineId) =>
//@ts-expect-error quickFetch is global
window.quickFetch.afterAuth(response => {
  const authData = response;
  const hasPerPipeline = authData.user.scopes.includes('crm-board-card-customization-per-pipeline');
  if (hasPerPipeline && pipelineId) {
    //@ts-expect-error quickFetch is global
    window.quickFetch.makeEarlyRequest(getRecordCardKey(objectTypeId, pipelineId), {
      //@ts-expect-error quickFetch is global
      url: window.quickFetch.getApiUrl(`/${BASE_URL}/${objectTypeId}/pipeline/${pipelineId}`),
      timeout: 15000,
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  } else {
    //@ts-expect-error quickFetch is global
    window.quickFetch.makeEarlyRequest(getRecordCardKey(objectTypeId), {
      //@ts-expect-error quickFetch is global
      url: window.quickFetch.getApiUrl(`/${BASE_URL}/${objectTypeId}/`),
      timeout: 5000,
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  }
});